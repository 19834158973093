import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["descubre", "filtro", "slide"]
	
	connect() {
		this.descubres = this.descubreTargets;
	}

	filtrar(event) {
		let descs = this.descubres.filter(descubre => event.currentTarget.value === "" || descubre.dataset.tipo == event.currentTarget.value);
		this.slideTarget.slide.reelTarget.innerHTML = "";
		descs.forEach((descubre, i) => { this.slideTarget.slide.reelTarget.append(descubre); });
		this.slideTarget.slide.forzarReajuste();
	}
}
