import { Controller } from "@hotwired/stimulus"

const cont_class = ".panel-campo";

export default class extends Controller {
	static targets = [ "programa", "promocion", "file" ]
	static values = { campo: String, valor: String }
	
	initialize() {
		this.campo = this.campoValue;
		this.valor = this.valorValue;
		this.element.removeAttribute("data-user-form-campo-value");
		this.element.removeAttribute("data-user-form-valor-value");
	}
	
	connect() {
		if(this.hasFileTarget) this.fileTarget.value = "";
	}
	
	agregarEtapa(event) {
		let campo = this.element.querySelector(`[name='${this.campo}']`) || document.createElement("input");
		campo.type = "hidden";
		campo.value = this.valor;
		campo.name = this.campo;
		if(!this.element.querySelector(`[name='${this.campo}']`)) event.currentTarget.append(campo);
		return true
	}
	
	deshabilitarCampos(event) {
		for(let [key, val] of Object.entries(event.params.valores)) {
			let element = this.element.querySelector(key), will_disable = event.currentTarget.value == event.params.trigger.toString(), label = element.closest(cont_class).querySelector("label");
			if(will_disable) element.value = val;
			if((will_disable && !element.disabled) || (!will_disable && element.disabled)) label.innerText = (will_disable ? label.innerText.replace(/\*$/, "") : label.innerText + "*")
			element.disabled = will_disable;
		}
	}
	
	agregarPromociones(event) {
		const promociones_url = "/actualizar-promociones/";
		let programa = this.programaTarget.value, promociones_selector = this.promocionTarget;
		fetch(promociones_url + programa + ".json")
		.then(response => response.json())
		.then((promociones) => {
			this.poblarSelect(promociones_selector, promociones);
		})
	}
	
	poblarSelect(selector, opciones) {
		selector.innerHTML = "";
		for(let opcion of opciones) {
			let opt = document.createElement("option");
			opt.value = opcion[1];
			opt.innerText = opcion[0];
			selector.append(opt);
		}
  }
  
  actualizarArchivo(event) {
  	let file_input = event.currentTarget;
  	file_input.previousElementSibling.innerText = (file_input.files.length == 0 ? "Subir archivo*" : file_input.files[0].name);
  }
}
