import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "cont" ]
  static values = { rec: String }
  
  initialize() {
  	this.key = this.recValue;
  }
  
  connect() {
  	let espera_grecaptcha = setInterval(() => {
  		if(typeof grecaptcha === "undefined" || !grecaptcha.hasOwnProperty("render")) return true;
  		this.loadCaptcha();
  		clearInterval(espera_grecaptcha);
  	}, 100);
  }
  
  loadCaptcha() {
		grecaptcha.ready(() => {
			let captcha = grecaptcha.render(this.contTarget, {sitekey: this.key, theme: 'light'});
			grecaptcha.reset(captcha);
		})
	}

}
