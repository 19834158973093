import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "frame", "form", "simple", "avanzada" ]
	
	connect() {
		this.simple_form = this.simpleTarget;
		this.avanzada_form = this.avanzadaTarget;
		this.avanzada_form.remove();
	}
	
	desplegar() {
		this.frameTargets[1].classList.add("hidden");
		this.frameTargets[0].classList.remove("hidden");
	}
	
	regresar() {
		this.frameTargets[0].classList.add("hidden");
		this.frameTargets[1].classList.remove("hidden");
		this.frameTargets[0].innerHTML = "";
	}
	
	simple() {
		this.formTarget.innerHTML = "";
		this.formTarget.append(this.simple_form);
	}
	
	avanzada() {
		this.formTarget.innerHTML = "";
		this.formTarget.append(this.avanzada_form);
	}
}
