import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["ventana", "toggler"]
	
	connect() {
		if(this.ventanaTarget.firstElementChild.offsetHeight < 50) {
			this.togglerController.remove();
			this.ventanaTarget.style.height = "";
		} else this.ventanaTarget.style.height = "2rem";
	}
  
  toggle(event) {
  	this.ventanaTarget.style.height = (this.ventanaTarget.style.height != "" ? "" : "2rem");
  	this.togglerTarget.innerText = (this.togglerTarget.innerText == "Ver más" ? "Ver menos" :" Ver más");
  }
}
