import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "fijo", "scroll" ]
	static classes = [ "hdrFijo" ]
	
	connect() {
		this.toggleEvent = this.toggle.bind(this);
		window.addEventListener("scroll", this.toggleEvent, {passive: true});
		this.toggle();
	}
	
	disconnect() {
		window.removeEventListener("scroll", this.toggleEvent, {passive: true});
		delete this.toggleEvent;
	}
	
	toggle() {
		if(document.documentElement.offsetHeight <= 1.5 * window.innerHeight && !this.element.classList.contains(this.hdrFijoClass)) return false
		if(window.scrollY > 280 && !this.element.classList.contains(this.hdrFijoClass)) {
			for(let element of this.fijoTargets) element.classList.add("!hidden");
			for(let element of this.scrollTargets) element.classList.remove("!hidden");
			this.element.classList.add(...this.hdrFijoClasses);
		} else if(window.scrollY == 0 && this.element.classList.contains(this.hdrFijoClass)) {
			for(let element of this.fijoTargets) element.classList.remove("!hidden");
			for(let element of this.scrollTargets) element.classList.add("!hidden");
			this.element.classList.remove(...this.hdrFijoClasses);
		}
	}
}
