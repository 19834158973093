import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["slide", "carrusel", "reel"]
	
	connect() {
		this.ajustarAltura();
	}
	
	ajustarAltura(event) {
		let altura = Math.max(...this.slideTargets.map(slide => slide.firstElementChild.scrollHeight)) + "px";
		this.slideTargets.forEach((slide) => { slide.style.height = altura });
		this.reelTarget.style.maxHeight = (parseInt(altura) * 2) + "px";
		this.carruselTarget.slide.index = 0;
		this.dispatch("ajuste", {});
	}
	
	
}
