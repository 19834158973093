import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  copiar({ params }) { 
  	let clipText = document.querySelector(params.id);
  	clipText.value = params.texto;
  	clipText.select();
  	navigator.clipboard.writeText(clipText.value).then(() => alert("Texto copiado"));
  }
}
