import { Controller } from "@hotwired/stimulus"

(function(prototype) {
  if (typeof prototype.requestSubmit == "function") return

  prototype.requestSubmit = function(submitter) {
    if (submitter) {
      validateSubmitter(submitter, this)
      submitter.click()
    } else {
      submitter = document.createElement("input")
      submitter.type = "submit"
      submitter.hidden = true
      this.appendChild(submitter)
      submitter.click()
      this.removeChild(submitter)
    }
  }

  function validateSubmitter(submitter, form) {
    submitter instanceof HTMLElement || raise(TypeError, "parameter 1 is not of type 'HTMLElement'")
    submitter.type == "submit" || raise(TypeError, "The specified element is not a submit button")
    submitter.form == form || raise(DOMException, "The specified element is not owned by this form element", "NotFoundError")
  }

  function raise(errorConstructor, message, name) {
    throw new errorConstructor("Failed to execute 'requestSubmit' on 'HTMLFormElement': " + message + ".", name)
  }
})(HTMLFormElement.prototype);

export default class extends Controller {
	static targets = [ "input", "orden" ]
	static classes = [ "active", "cont", "error", "pagina" ]
	
	initialize() {
		this.allowedSubmit = true;
		this.timerText = 0;
	}
	
	limpiar() {
		let forma = this.element.querySelector("form");
		for(let input of this.inputTargets) this.limpiarCampo(input);
		forma.requestSubmit();
	}
	
	limpiarCond(campos_excluidos) {
		for(let campo of campos_excluidos) {
		  let input = this.inputTargets.find(input => new RegExp("\\[" + campo.replace(/.+?[]{}*/, "") + "\\]").test(input.name));
			if(input != null && typeof input !== "undefined") this.limpiarCampo(input)
		}
	}
	
	limpiarCampo(campo) {
		if(campo.type == "checkbox" || campo.type == "radio") {
			if(campo.checked) {
				campo.checked = false;
				campo.checkedPrev = false;
			}
		} else campo.value = "";
	}
	
	inputTargetConnected(element) {
		if(element.nodeName == "SELECT" && element.options.length > 0) element.value = (typeof element.dataset.value === "undefined" ? element.options[0].value : element.dataset.value);
		else if(typeof element.dataset.value !== "undefined") element.value = element.dataset.value;
	}
	
	ordenar(event) {
	  if(event.params.orden != "asc" && event.params.orden != "desc") return;
		let forma = this.element.querySelector("form");
		event.currentTarget.classList[event.currentTarget.classList.contains(this.activeClass) ? "remove" : "add"](...this.activeClasses);
		this.element.querySelector(`[data-form-orden-param='${event.params.orden == "asc" ? "desc" : "asc"}']`).classList.remove(...this.activeClasses);
		if(event.currentTarget.classList.contains(this.activeClass)) {
			if(forma.querySelector(`[name="orden[${event.params.campo}]"]`) != null) forma.querySelector(`[name="orden[${event.params.campo}]"]`).value = event.params.orden;
			else {
				let input = document.createElement("input");
				input.type = "hidden";
				input.value = event.params.orden;
				input.name = `orden[${event.params.campo}]`;
				forma.appendChild(input);
			}
  	} else forma.querySelector(`[name="orden[${event.params.campo}]"]`).remove();
  	forma.requestSubmit();
	}
	
	offset(event) {
		event.preventDefault();
		if(!parseInt(event.params.offset) == event.params.offset) return;
		let forma = this.element.querySelector("form");
  	if(forma.querySelector("input[name='offset']") != null) forma.querySelector("input[name='offset']").value = event.params.offset;
  	else {
  		let input = document.createElement("input");
  		input.type = "hidden";
  		input.value = event.params.offset;
  		input.name = `offset`;
  		forma.appendChild(input);
  	}
  	forma.requestSubmit();
	}
	
	delayEnviar(event) {
		clearTimeout(this.timerText);
		this.timerText = setTimeout(() => { this.enviar(event); }, 500);
	}
  
  enviar(event) {
  	this.limpiarOffset();
  	let campos = event.params.campos.split(",").filter(el => el != ""), forma = this.element.querySelector("form");
		if(campos.length > 0) this.limpiarCond(campos);
		forma.requestSubmit();
  }
  
  limpiarOffset() {
		this.pagina = 0;
		let forma = this.element.querySelector("form"), offset = forma.querySelector("[name='offset']");
		if(offset != null) offset.remove();
	}
	
	limpiarError(event) {
		let cont = event.currentTarget.closest(`.${this.contClass}`);
		if(cont.querySelector(`.${this.errorClass}`) == null) return false;
		if(cont.querySelector(".field_with_errors") != null) cont.querySelector(".field_with_errors").classList.remove("field_with_errors");
		if(cont.querySelector(".label_with_errors") != null) cont.querySelector(".label_with_errors").classList.remove("label_with_errors");
		cont.querySelector(`.${this.errorClass}`).remove();
		event.currentTarget.classList.remove("with-error");
	}
	
	allowSubmit({ detail }) {
		this.allowedSubmit = detail.enabled;
		this.element.querySelector("button[type='submit']").disabled = !this.allowedSubmit;
	}
	
	confirmSubmit(event) { if(!this.allowedSubmit) event.preventDefault(); }
}
