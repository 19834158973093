import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "seccion" ]
  static classes = [ "open" ]
  static values = { multi: {type: Boolean, default: false}, highlight: {type: Boolean, default: false} }
  
  connect() {
  	this.collapseEnd = this.collapseEnd.bind(this);
  	this.seccionTargets.forEach((seccion) => {
			seccion.querySelector("summary ~ *").addEventListener("animationend", this.collapseEnd);
			if(seccion.hasAttribute("open")) {
				seccion.querySelector("summary").classList.add(...this.openClasses);
				if(this.highlightValue) seccion.classList.add("highlight");
			}
		});
  }
	
	collapseEnd(event) {
		if(event.animationName === "rev-sweep") {
			if(!event.target.parentElement.classList.contains("highlight")) event.target.parentElement.querySelector("summary").classList.remove(...this.openClasses);
			event.target.parentElement.removeAttribute("open");
			event.target.parentElement.classList.remove("closed");
		}
	}
	
	collapse(event) {
		if(event.currentTarget.parentElement.open) {
			event.preventDefault();
			event.currentTarget.parentElement.classList.add("closed");
		} else {
			event.currentTarget.classList.add(...this.openClasses);
			let former = this.seccionTargets.find((seccion) => seccion.hasAttribute("open") && seccion != event.currentTarget.parentElement);
			if(former && !this.multiValue) former.classList.add("closed");
		}
	}
}
