import { Controller } from "@hotwired/stimulus"
import Trix from "trix"

const colores = [["#0F4756", "rgb(15, 71, 86)"], ["#007196", "rgb(0, 113, 150)"], ["#C19516", "rgb(193, 149, 22)"], ["#9D9D9D", "rgb(157, 157, 157)"], ["#A6A6A6", "rgb(166, 166, 166)"]];

const textActions = [{attribute: "underline", descripcion: "Subrayado", icon: "fa-solid fa-underline text-black"}, {action: 'x-size', descripcion: "Tamaño de fuente", icon: "fa-solid fa-text-height text-black"}, {action: "x-script", descripcion: "Indizado", icon: "fa-solid fa-superscript text-black"}, {action: 'x-color', descripcion: "Color", icon: "fa-solid fa-fill-drip text-black"}];
const blockActions = [{position: "afterbegin", action: "x-heading", descripcion: "Encabezado", icon: "fa-solid fa-heading text-black"}, {action: "x-hr", descripcion: "Regla horizontal", icon: "fa-solid fa-minus text-black"}, {action: "x-align", descripcion: "Alineación", icon: "fa-solid fa-align-justify text-black"}];
const fileActions = [/*{action: "x-tabla", descripcion: "Tabla", icon: "fa-solid fa-table text-black"}*/];

const alignsCaja = `<div class="trix-dialog trix-dialog--align" data-trix-dialog="x-align" data-trix-dialog-attribute="x-align"><div class="trix-dialog__link-fields"><div class="trix-button-group trix-scripts">${['center', 'right'].map( (align, i) => { return `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-align-${align}" title="${align.charAt(0).toUpperCase() + align.slice(1)}" tabindex="-1" data-trix-attribute="${align}" data-trix-method="hideDialog"><div class="inline-block text-[14px]"><i class="fa-solid fa-align-${align}" aria-hidden="true"></i></div></button>` }).join('')}</div></div></div>`;
const sizesCaja = `<div class="trix-dialog trix-dialog--size" data-trix-dialog="x-size" data-trix-dialog-attribute="x-size"><div class="trix-dialog__link-fields"><div class="trix-button-group trix-sizes">${['larger', 'smaller'].map( (size, i) => { return `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-size-${size}" title="${size.charAt(0).toUpperCase() + size.slice(1)}" tabindex="-1" data-trix-attribute="${size}" data-trix-method="hideDialog"><div class="inline-block"><i class="fa-solid fa-font ${i == 0 ? 'text-lg' : 'text-xs'}" aria-hidden="true"></i></div></button>` }).join('')}</div></div></div>`;
const scriptsCaja = `<div class="trix-dialog trix-dialog--script" data-trix-dialog="x-script" data-trix-dialog-attribute="x-script"><div class="trix-dialog__link-fields"><div class="trix-button-group trix-scripts">${['super', 'sub'].map( (tall, i) => { return `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-script-${tall}" title="${tall.charAt(0).toUpperCase() + tall.slice(1)}índice" tabindex="-1" data-trix-attribute="${tall.substring(0,3)}" data-trix-method="hideDialog"><div class="inline-block text-[14px]"><i class="fa-solid fa-${tall}script" aria-hidden="true"></i></div></button>` }).join('')}</div></div></div>`;
const coloresCaja = `<div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color"><div class="trix-dialog__link-fields flex-col"><div class="trix-button-group trix-colores">${colores.map( (color, i) => { return `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-color-${color[0].substring(1)}" title="Color ${color[0]}" tabindex="-1" data-trix-attribute="color${(i + 1)}" data-trix-method="hideDialog"><div class="inline-block before:content-a before:font-black before:font-fa fa-fw" style="color: ${color[0]}"></div></button>` }).join('')}</div><div class="trix-button-group trix-colores">${colores.map( (color, i) => { return `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-bgColor-${color[0].substring(1)}" title="Remarcado ${color[0]}" tabindex="-1" data-trix-attribute="bgColor${(i + 1)}" data-trix-method="hideDialog"><div class="inline-block before:content-highlighter before:font-fa before:font-black fa-fw" style="color: ${color[0]}"></div></button>` }).join('')}</div></div></div>`;
const headingsCaja = `<div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading"><div class="trix-dialog__link-fields trix-dialog-caja"><div class="trix-button-group trix-colores">${[1, 2, 3, 4, 5, 6].map( (h) => { return `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-heading-heading${h}" title="Encabezado ${h}" tabindex="-1" data-trix-attribute="heading${h}" data-trix-method="hideDialog"><div style="display:inline-block; font-size: ${2 * (11 - h)}px;">H${h}</div></button>` }).join('')}</div></div></div>`;

const createValueParser = () => {
	return function(element) {
		let value;
		while(element && element.tagName !== "TRIX-EDITOR") {
		  if(value = element.style[this.styleProperty]) return value;
		  element = element.parentElement;
		}
	};
}

(() => {
	Trix.config.textAttributes.underline = { tagName: "u", parser: (element) => { return element.style.textDecoration == "underline" }, inheritable: true }
	Trix.config.textAttributes.larger = { styleProperty: "fontSize", style: { fontSize: "larger" }, parser: (element) => { createValueParser(); return element.style.fontSize == "larger" }, inheritable: true }
	Trix.config.textAttributes.smaller = { styleProperty: "fontSize", style: { fontSize: "smaller" }, parser: (element) => { createValueParser(); return element.style.fontSize == "smaller" }, inheritable: true }
	Trix.config.textAttributes.sup = { tagName: "sup", parser: (element) => { return element.style.verticalAlign == "super" }, inheritable: true }
	Trix.config.textAttributes.sub = { tagName: "sub", parser: (element) => { return element.style.verticalAlign == "sub" }, inheritable: true }
	Trix.config.textAttributes.italic.tagName = "i";
	Trix.config.textAttributes.bold.tagName = "b";

	colores.forEach((color, i) => {
		Trix.config.textAttributes[`color${(i + 1)}`] = { styleProperty: "color", style: { color: color[1] }, parser: (element) => { createValueParser(); return element.style.color == color[1] }, inheritable: true }
		Trix.config.textAttributes[`bgColor${(i + 1)}`] = { styleProperty: "background-color", style: { backgroundColor: color[1] }, parser: (element) => { createValueParser(); return element.style.backgroundColor == color[1] }, inheritable: true }
	});
	[1, 2, 3, 4, 5, 6].forEach( (i) => {
		Trix.config.blockAttributes[`heading${i}`] = { breakOnReturn: true, group: false, tagName: `h${i}`, terminal: false }
	});
	
	["left", "center", "right"].forEach( (align) => {
		/*if(customElements.get(`align-${align}`) === undefined)*/ customElements.define(`align-${align}`, class extends HTMLElement { constructor() { super(); } });
		Trix.config.blockAttributes[align] = { tagName: `align-${align}`, breakOnReturn: true, group: true, terminal: false }
	});

	Trix.Attachment.previewablePattern = /^(?!.*rubyonrails)/;
})();

export default class extends Controller {
	connect() {
		this.extenderToolbar = this.extenderToolbar.bind(this);
		this.detectarAccion = this.detectarAccion.bind(this);
		window.addEventListener("trix-initialize", this.extenderToolbar, {once: true});
		this.element.addEventListener("trix-action-invoke", this.detectarAccion);
	}
	
	extenderToolbar() {
		this.poblarToolbar(this.textGroup, textActions);
		this.poblarToolbar(this.blockGroup, blockActions);
		this.poblarToolbar(this.fileGroup, fileActions);
		if(this.blockGroup.querySelector(".trix-button--icon-heading-1") != null) this.blockGroup.querySelector(".trix-button--icon-heading-1").remove();
		this.dialogGroup.insertAdjacentHTML("beforeend", alignsCaja);
		this.dialogGroup.insertAdjacentHTML("beforeend", headingsCaja);
		this.dialogGroup.insertAdjacentHTML("beforeend", sizesCaja);
		this.dialogGroup.insertAdjacentHTML("beforeend", scriptsCaja);
		this.dialogGroup.insertAdjacentHTML("beforeend", coloresCaja);
		if(this.dialogGroup.querySelector("[data-trix-input][type='url']") == null) this.dialogGroup.querySelector("[data-trix-input][type='url']").setAttribute("type", "text");
		if(this.dialogGroup.querySelector("[data-role='embed_el']") == null) this.dialogGroup.querySelector("[data-trix-dialog='href']").insertAdjacentHTML("beforeend", `<div data-role="embed_el" class="mt-4 text-sm"><div class="link_to_embed link_to_embed--new">Embeber contenido?<input class="px-4 text-white bg-colmex1 transition-colors duration-300 ease-linear hover:bg-colmex3 cursor-pointer btn btn-tertiary outline btn-small ml-3" type="button" data-role="embed_button" value="Embeber"></div></div>`);
		this.resetEditor();
	}
	
	poblarToolbar(grupo, actions) {
		actions.forEach(action => grupo.insertAdjacentHTML(action.position || "beforeend", `<button type="button" class="trix-button trix-button-icon" data-trix-${action.hasOwnProperty("action") ? "action" : "attribute"}="${action.action || action.attribute}" title="${action.descripcion}" tabindex="-1"><div class="inline-block text-[14px] text-gris2 flex justify-center items-center"><i class='${action.icon}'></i></div></button>`) );
	}
	
	desactivar(opts, attr, element) { opts.forEach( opt => { if(attr != opt) element.deactivateAttribute(opt); }) };
	
	detectarAccion(event) {
		if(/x-align/.test(event.actionName)) this.desactivar(["right", "center", "left"], event.actionName.split("-")[2], this.editor);
		if(/x-(size|script)/.test(event.actionName)) this.desactivar((/x-size/.test(event.actionName) ? ["larger", "smaller"] : ["sub", "super"]), event.actionName.split("-")[2], editor);
		else if(/color/.test(event.actionName)) {
			const color = event.actionName.match(/([0-9A-Fa-f]{6})$/)[1], accion = event.actionName.split('-')[1];
			colores.forEach( (col, i) => { if(col[0].substring(1) != color) this.editor.deactivateAttribute(`${accion + (i + 1)}`); });
		} else if(/heading/.test(event.actionName)) this.desactivar(["heading1", "heading2", "heading3", "heading4", "heading5", "heading6"], event.actionName.split("-")[2], this.editor);
		else if(/x-hr/.test(event.actionName)) {
			let attachment = new Trix.Attachment({content: '<hr class="my-6 border border-solid border-gris3"></hr>', contentType: "vnd.rubyonrails.horizontal-rule.html"});
			this.editor.insertAttachment(attachment);
		}
	}

	resetEditor() {
		this.reset();
		this.href.addEventListener("input", this.didInput.bind(this));
    this.href.addEventListener("focusin", this.didInput.bind(this));
    this.embedElement.addEventListener("click", this.embed.bind(this));
	}
	
	didInput(event) {
    let value = event.target.value.trim();
    let params = this.checkURL(value);

    if (params != null) this.fetch(params);
    else this.reset();
  }
  
  checkURL(url) {
  	try {
  		let parsed_url = new URL(url), path = parsed_url.pathname.split("/"), host = parsed_url.hostname, params;
  		if(host == "www.youtube.com" || host == "youtu.be") params = ["youtube", (host == "www.youtube.com" ? parsed_url.searchParams.get("v") : path[1]), parsed_url.searchParams.get("list")];
  		else if(host == "twitter.com") params = ["twitter", path[1], path[2], path[3]];
  		else if(host == "open.spotify.com") params = ["spotify", path[2]];
  		else if(host == "vimeo.com") params = ["vimeo", path[1]];
  		else if(host == "www.facebook.com") params = ["facebook", path[1], path[3]];
  		else if(host == "www.pinterest.com") params = ["pinterest", path[1], path[2]];
  		else if(host == "www.instagram.com") params = ["instagram", path[2]];
  		else if(host == "www.tiktok.com") params = ["tiktok", path[1], path[3]];
  		else return null
  		params = params.filter(val => val !== "undefined" && val != null && val.trim() != "");
  		return (params.length <= 1 ? null : params.join(" "))
  	} catch(error) {
  		if(/^paypal:[0-9A-Za-z]+$/.test(url)) return "paypal " + url.split(":")[1];
  		if(/^#[a-zA-Z0-9-_]+$/.test(url)) return "anchor " + url.substring(1);
  		return null
  	}
  }

  fetch(params) {
    fetch(`/embed/${encodeURIComponent(params)}`)
		.then(resp => resp.json())
		.then((embed) => {
			this.currentEmbed = embed;
			this.embedContainer.classList.remove("hidden");
		}).catch(error => this.reset.bind(this)); 
  }

  embed(event) {
    if (this.currentEmbed == null) { return }

    let attachment = new Trix.Attachment(this.currentEmbed);
    this.editor.insertAttachment(attachment);
    this.editor.selectionManager.unlock();
    this.element.focus();
  }

  reset() {
    this.embedContainer.classList.add("hidden");
    this.currentEmbed = null
  }
	
  get editor() { return this.element.editor || this.element.parentNode.querySelector("trix-editor") }
  get toolbar() { return this.element.toolbarElement || this.element.parentNode.querySelector("trix-toolbar") }
  get href() { return this.toolbar.querySelector("[data-trix-input][name='href']") }
  get embedContainer() { return this.toolbar.querySelector("[data-role='embed_el']") }
  get embedElement() { return this.toolbar.querySelector("[data-role='embed_button']") }
  get textGroup() { return this.toolbar.querySelector("[data-trix-button-group='text-tools']") }
  get blockGroup() { return this.toolbar.querySelector("[data-trix-button-group='block-tools']") }
  get dialogGroup() { return this.toolbar.querySelector("[data-trix-dialogs]") }
  get fileGroup() { return this.toolbar.querySelector("[data-trix-button-group='file-tools']") }
}
