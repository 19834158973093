import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "opcion" ]
	
	opcionTargetConnected(element) { element.checkedPrev = element.checked; }
	
	toggle(event) {
		let opcion = event.currentTarget;
		opcion.checkedPrev = !opcion.checkedPrev;
		opcion.checked = opcion.checkedPrev;
	}
}
