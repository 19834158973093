import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	
	connect() {
    let script = document.createElement("script");
    script.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-57bb682464651c22";
    script.defer = true;
    this.removeAddthis();
    document.body.append(script);
	}
	
	disconnect() {
		let script = document.querySelector("script[src='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-57bb682464651c22']")
		if(script != null) script.remove();
	}
	
	removeAddthis() {
		for (let varname in window) {
			if (/^addthis/.test(varname) || /^_at/.test(varname))	delete window[varname];
		}
		window.addthis_share = null;

		// Remove all global properties set by addthis, otherwise it won't reinitialize
		if(window.addthis) {
			window.addthis = null;
			window._adr = null;
			window._atc = null;
			window._atd = null;
			window._ate = null;
			window._atr = null;
			window._atw = null;
		}
	}
}
