import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

	connect() { this.beginScrollEvent = this.beginScroll.bind(this); }
	disconnect() { delete this.beginScrollEvent; }

	scrollTo(event) {
		try {
			let hash = new URL(event.currentTarget.href).hash;
			this.elemento = (hash != "" ? document.querySelector(hash) : document.documentElement);
			this.top_f = Math.max(Math.round(this.elemento.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top - document.querySelector("header").offsetHeight), 0);
			this.signo = Math.sign(this.top_f - window.scrollY);
			event.preventDefault();
			this.cancelScroll();
			this.scrolling = requestAnimationFrame(this.beginScrollEvent);
		} catch(e) { return false; }
	}
	
	cancelScroll() {
		if(this.scrolling) cancelAnimationFrame(this.scrolling);
	}
	
	beginScroll() {
		let scrollY = Math[this.signo >= 0 ? "min" : "max"](window.scrollY + this.signo * 80, this.top_f);
		scrollTo({top: scrollY});
		if(window.scrollY != this.top_f && window.scrollY != window.scrollMaxY) this.scrolling = requestAnimationFrame(this.beginScrollEvent);
		else {
			scrollTo({top: Math.max(Math.round(this.elemento.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top - document.querySelector("header").offsetHeight), 0)});
			this.dispatch("end", {detail: {event: event}});
		}
	}
}
