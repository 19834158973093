import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "reel", "slide", "flecha", "dots", "dot" ]
  static classes = [ "dot", "dotActive", "flechaInactive", "deslizar" ]
  static values = { timer: Number, pause: { type: Boolean, default: false }, grupo: { type: Boolean, default: false }, infinito: { type: Boolean, default: true }, vertical: { type: Boolean, default: false }, draggable: { type: Boolean, default: false }, scrollSpeed: {type: Number, default: 3}, dragFriction: {type: Number, default: 0.89}, hilerasVertical: {type: Array, default: [1,1,1,1,1] } }
  
  connect() {
  	this.element.slide = this;
  	this.index = 0;
  	this.slides = this.slides_pantalla;
  	this.desplazarEvent = this.desplazar.bind(this);
  	if(!this.infinitoValue) this.revisarFlechas();
  	this.configurarResizeObserver();
  }
  
  disconnect() {
  	this.reiniciarTempo();
  	this.resizeObserver.unobserve(document.documentElement);
  	if(!this.hasTimerValue && this.timerValue <= 0) this.removerTempo();
  	if(this.draggableVlaue) this.removerDrag();
  	delete this.temporizador;
  	delete this.index;
  	delete this.slides;
  }
  
  dotsTargetConnected(element) {
  	this.poblarPuntos();
  }
  
  timerValueChanged(value) {
  	this.removerTempo();
  	if(value > 0) this.configurarTemporizacion();
  }
  
  pauseValueChanged(value) {
  	if(value) {
			this.element.addEventListener("mousemove", this.reiniciarTempoEvent, {passive: true});
			this.element.addEventListener("mouseleave", this.temporizarEvent);
		} else {
			this.element.removeEventListener("mousemove", this.reiniciarTempoEvent, {passive: true});
			this.element.removeEventListener("mouseleave", this.temporizarEvent);
		}
  }
  
  draggableValueChanged(value) {
  	if(this.draggableValue) {
  		this.reelTarget.classList.add("cursor-grab");
  		this.startDragEvent = this.startDrag.bind(this);
  		this.dragEvent = this.drag.bind(this);
  		this.stopDragEvent = this.stopDrag.bind(this);
  		this.momentumLoop = this.momentumLoop.bind(this);
  		this.reelTarget.addEventListener("mousedown", this.startDragEvent);
  		this.reelTarget.addEventListener("touchstart", this.startDragEvent);
  		this.reelTarget.addEventListener("touchmove", this.dragEvent, {passive: true});
  		this.reelTarget.addEventListener("touchend", this.stopDragEvent);
  		this.reelTarget.addEventListener("touchcancel", this.stopDragEvent);
  	} else this.removerDrag();
  }
  
  dotTargetConnected(element) {
  	if(element.dataset.slideIndexParam == this.index) {
  		element.classList.add(...this.dotActiveClasses);
  		element.active = "";
  	}
  }
  
  removerDrag() {
  	this.reelTarget.classList.remove("cursor-grab");
  	this.reelTarget.removeEventListener("mousedown", this.startDragEvent);
		this.reelTarget.removeEventListener("mousemove", this.dragEvent, {passive: true});
		this.reelTarget.removeEventListener("mouseleave", this.stopDragEvent);
		this.reelTarget.removeEventListener("mouseup", this.stopDragEvent);
		this.reelTarget.removeEventListener("touchstart", this.startDragEvent);
		this.reelTarget.removeEventListener("touchmove", this.dragEvent, {passive: true});
		this.reelTarget.removeEventListener("touchend", this.stopDragEvent);
		this.reelTarget.removeEventListener("touchcancel", this.stopDragEvent);
  }
  
  removerTempo() {
  	if(this.intersectionObserver) this.intersectionObserver.unobserve(this.element);
  	document.removeEventListener("visibilitychange", this.manejarVisibility);
  	if(this.pauseValue) {
			this.element.removeEventListener("mousemove", this.reiniciarTempoEvent, {passive: true});
			this.element.removeEventListener("mouseleave", this.temporizarEvent);
		}
  }
  
  configurarResizeObserver() {
  	this.manejarResizeObserver = this.manejarResize.bind(this);
  	this.resizeObserver = new ResizeObserver(this.manejarResizeObserver);
  	this.resizeObserver.observe(document.documentElement);
  }
  
  configurarTemporizacion() {
  	this.manejarVisibilityEvent = this.manejarVisibility.bind(this);
  	this.manejarIntersectionObserver = this.manejarIntersection.bind(this);
  	this.reiniciarTempoEvent = this.reiniciarTempo.bind(this);
  	this.temporizarEvent = this.temporizar.bind(this);
  	document.addEventListener("visibilitychange", this.manejarVisibilityEvent);
  	this.intersectionObserver = new IntersectionObserver(this.manejarIntersectionObserver, {rootMargin: "5px"});
  	this.intersectionObserver.observe(this.element);
  }
  
  manejarResize(entries) {
		for(let entry of entries) {
			if(this.verticalValue) this.ajustarAltura();
			this.reajustar();
		}
  }
  
  manejarVisibility() {
		if(document.visibilityState === "visible") this.temporizar();
		else this.reiniciarTempo();
  }
  
  manejarIntersection(entries, observer) {
  	for(let entry of entries) {
  		if(entry.isIntersecting) {
  			this.element.setAttribute("isvisible", true);
  			this.temporizar();
  		} else {
  			this.element.removeAttribute("isvisible");
  			this.reiniciarTempo();
  		}
  	}
  }
  
  navegar(event) { 
  	if(this.dotTargets.indexOf(event.currentTarget) != -1) this.index = event.params.index;
  	else if(this.flechaTargets.indexOf(event.currentTarget) != -1 && (this.infinitoValue || !event.currentTarget.classList.contains(this.flechaInactiveClass))) {
  		this.recorrer(event.params.sentido);
  		if(this.hasTimerValue) this.temporizar();
  	}
  	this.comienzoDesplazar();
  }
  
  recorrer(sentido) {
  	if(sentido == "siguiente") {
  		this.index = (this.index == this.num_hileras - this.slides_pantalla ? 0 : Math.min(this.index + (this.grupoValue ? this.slides_pantalla : 1), this.num_hileras - this.slides_pantalla) );
  		
  	} else if(sentido == "anterior") this.index = (this.index == 0 ? this.num_hileras - this.slides_pantalla : Math.max((this.grupoValue ? (this.index == this.num_hileras - this.slides_pantalla && (parseInt(this.pantallas_float) != this.pantallas_float) ? (Math.floor(this.pantallas_float) - 1) * this.slides_pantalla : this.index - this.slides_pantalla) : this.index - 1), 0) );
  }
  
  reajustar() {
  	if(this.slides != this.slides_pantalla) {
  		this.index = 0;
  		this.slides = this.slides_pantalla;
  	}
  	if(this.hasDotsTarget && this.dotTargets.length != this.max_puntos) this.poblarPuntos();
  	this.comienzoDesplazar();
  	this.temporizar();
  }
  
  comienzoDesplazar() {
  	let letra = (this.verticalValue ? 'y' : 'x');
  	this.untrackInertia();
  	this.terminarDesplazar();
  	this.iter = 1;
  	this.scroll_i = this.reelTarget[`scroll${this.verticalValue ? "Top" : "Left"}`];
  	this.scroll_f = Math.round(this.slideTargets[this.index * this.num_columnas].getBoundingClientRect()[letra] - this.slideTarget.getBoundingClientRect()[letra]);
  	if(this.hasDotsTarget && this.dotTargets.length > 0) this.activarPunto(this.dotTargets.find(punto => punto.dataset.slideIndexParam == this.index), this.dotTargets.find(punto => punto.active == "") );
		if(!this.infinitoValue) this.revisarFlechas();
  	this.desplazar_f = requestAnimationFrame(this.desplazarEvent);
  }
  
  terminarDesplazar() {
  	if(this.desplazar_f) cancelAnimationFrame(this.desplazar_f);
  }
  
  desplazar() {
		this.reelTarget.scroll({[this.verticalValue ? "top" : "left"]: this.reelTarget[`scroll${this.verticalValue ? "Top" : "Left"}`] + (this.scroll_f - this.scroll_i) / 20});
		this.iter += 1;
		if(this.iter <= 20) this.deplazar_f = requestAnimationFrame(this.desplazarEvent);
		else this.reelTarget.scroll({[this.verticalValue ? "top" : "left"]: this.scroll_f});
  }
  
  // Funciones relativas a las viñetas de carrusel.
  activarPunto(punto, punto_ant) {
  	if(punto_ant != null && typeof punto_ant !== "undefined") {
			punto_ant.classList.remove(...this.dotActiveClasses);
			delete punto_ant.active;
		}
  	punto.classList.add(...this.dotActiveClasses);
  	punto.active = "";
  }
  
  poblarPuntos() {
  	this.dotsTarget.innerHTML = "";
  	for(let i = 0; i < this.max_puntos; i++) {
  		let punto = document.createElement("li");
  		punto.classList.add(...this.dotClasses);
  		punto.dataset.slideIndexParam = (!this.grupoValue ? i : Math.min(i * this.slides_pantalla, this.num_hileras - this.slides_pantalla) );
			this.dotsTarget.appendChild(punto);
  		punto.dataset.slideTarget = "dot";
  		punto.dataset.action = "click->slide#navegar";
  	}
  }
  
  revisarFlechas() { for(let flecha of this.flechaTargets) flecha.classList[(flecha.dataset.slideSentidoParam == "anterior" && this.index == 0) || (flecha.dataset.slideSentidoParam == "siguiente" && this.index == this.num_hileras - this.slides_pantalla) ? "add" : "remove"](...this.flechaInactiveClasses); }

	// Funciones referentes a la temporización del carrusel.
  temporizar() {
  	if(this.temporizador) this.reiniciarTempo();
  	if(!this.hasTimerValue || !this.en_pantalla || this.dragging) return false;
  	this.temporizador = setInterval(() => { this.recorrer("siguiente"); this.comienzoDesplazar(); }, this.timerValue * 1000);
  }
  
  reiniciarTempo() { if(this.temporizador) clearInterval(this.temporizador); }
  
  // Funciones asociadas al carrusel en formato vertical.
  ajustarAltura(event) {
		let altura = Math.max(...this.slideTargets.map(slide => slide.firstElementChild.scrollHeight)) + "px";
		this.slideTargets.forEach((slide) => { slide.style.height = altura });
		this.reelTarget.style.maxHeight = (parseInt(altura) * this.hilerasPantalla()) + "px";
		this.reajustar();
	}
	
	hilerasPantalla() {
		let ancho = window.innerWidth, indice;
		if(ancho >= 1600) indice = 4;
		else if(ancho >= 1200) indice = 3;
		else if(ancho >= 960) indice = 2;
		else if (ancho >= 640) indice = 1;
		else indice = 0;
		return this.hilerasVerticalValue[indice]
	}
	
	// Funciones asociadas al arrastre del carrusel.
	startDrag(event) {
		if(!this.draggableValue || (event.type == "mousedown" && event.button != 0)) return false;
		if(event.type == "touchstart") event.preventDefault();
		else if(event.type == "mousedown") {
			this.reelTarget.addEventListener("mousemove", this.dragEvent, {passive: true});
			this.reelTarget.addEventListener("mouseleave", this.stopDragEvent);
			this.reelTarget.addEventListener("mouseup", this.stopDragEvent);
		}
		this.dragging = true;
		this.drag_i = (event.type == "touchstart" ? event.touches[0] : event)[`page${this.verticalValue ? 'Y' : 'X'}`] - this.reelTarget[`offset${this.verticalValue ? 'Top' : 'Left'}`];
		this.scroll_i = this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}`];
		this.reelTarget.classList.remove("cursor-grab");
		this.reelTarget.classList.add("cursor-grabbing");
		this.untrackInertia();
	}
	
	drag(event) {
		if(this.dragging) {
			if(event.type == "touchmove") event.preventDefault();
			this.delta = ((event.type == "touchmove" ? event.touches[0] : event)[`page${this.verticalValue ? 'Y' : 'X'}`] - this.reelTarget[`offset${this.verticalValue ? 'Top' : 'Left'}`] - this.drag_i) * this.scrollSpeedValue;
			this.drag_speed = this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}`];
			this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}`] = this.scroll_i - this.delta;
			this.drag_speed = this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}`] - this.drag_speed;
		}
	}
	
	stopDrag(event) {
		if(!this.draggableValue || (event.type == "mouseup" && event.button != 0)) return false;
		if(event.type == "touchend" || event.type == "touchcancel") event.preventDefault();
		else if(event.type == "mouseup" || event.type == "mouseleave") {
			this.reelTarget.removeEventListener("mousemove", this.dragEvent, {passive: true});
			this.reelTarget.removeEventListener("mouseleave", this.stopDragEvent);
			this.reelTarget.removeEventListener("mouseup", this.stopDragEvent);
		}
		delete this.dragging;
		delete this.drag_i;
		delete this.scroll_i;
		delete this.delta;
		this.reelTarget.classList.remove("cursor-grabbing");
		this.reelTarget.classList.add("cursor-grab");
		this.trackInertia();
	}
	
	trackInertia() {
		this.terminarDesplazar();
		this.untrackInertia();
		this.momentum = requestAnimationFrame(this.momentumLoop);
	}
	
	untrackInertia() {
		if(this.momentum) cancelAnimationFrame(this.momentum);
	}
	
	momentumLoop() {
		this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}`] += this.drag_speed;
		this.drag_speed *= this.dragFrictionValue;
		if (Math.abs(this.drag_speed) > 1.5) this.momentum = requestAnimationFrame(this.momentumLoop);
		else {
			this.untrackInertia();
			this.index = Math.round(this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}`] / this.reelTarget[`scroll${this.verticalValue ? 'Top' : 'Left'}Max`] * (this.max_puntos - 1));
			this.comienzoDesplazar();
			this.temporizar()
		};
	}

	get dimension() { return (this.verticalValue ? 'Height' : 'Width') }
	get num_pantallas() { return Math.round(this.reelTarget[`scroll${this.dimension}`]/this.reelTarget[`offset${this.dimension}`]) }
	get pantallas_float() { return this.reelTarget[`scroll${this.dimension}`]/this.reelTarget[`offset${this.dimension}`] } 
	get slides_pantalla() { return Math.round(this.reelTarget[`offset${this.dimension}`]/this.slideTarget[`offset${this.dimension}`]) }
	get max_puntos() { return Math.max(1, (this.grupoValue ? this.num_pantallas : this.num_hileras - this.slides_pantalla + 1 /*this.num_hileras*/)) }
	get num_hileras() {
		let denom = this.slideTarget[`offset${this.dimension}`];
		return Math.round(this.reelTarget[`scroll${this.dimension}`]/(denom == 0 ? 1 : denom)) }
	get num_columnas() {
		let denom = this.slideTarget[`offset${this.verticalValue ? 'Width' : 'Height'}`];
	return Math.round(this.reelTarget[`scroll${this.verticalValue ? 'Width' : 'Height'}`]/(denom == 0 ? 1 : denom)) }
	get en_pantalla() { return this.element.hasAttribute("isvisible"); }
}
