import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  	this.loadBg = this.loadBackground.bind(this);
		this.elementObserver = new IntersectionObserver(this.loadBg, {marginRoot: "5px", threshold: 0.01});
  	this.elementObserver.observe(this.element);
  }
  
  disconnect() {
    delete this.elementObserver;
		delete this.loadBg;
  }
  
  loadBackground(entries, observer) {
		entries.forEach((entry) => {
			if(entry.intersectionRatio > 0) {
				entry.target.style.backgroundImage = `url('${entry.target.dataset.src}')`;
				this.elementObserver.unobserve(entry.target);
			}
		})
	}
}
