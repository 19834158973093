import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "check" ]
	
	connect() {
		this.determinarHidden();
	}

  determinarHidden() {
  	let hidden = true;
		for(let checkbox of this.checkTargets) {
			if(checkbox.checked) {
				hidden = false;
				break;
			}
  	}
  	this.procesarHidden(hidden);
  }
  
  procesarHidden(mostrar_hidden) {
  	let campo_hidden = this.element.querySelector("input[type='hidden'][name$='[]']");
  	if(!mostrar_hidden && campo_hidden) campo_hidden.remove();
  	else if(mostrar_hidden && !campo_hidden) this.agregarHidden();
  }
  
  agregarHidden() {
  	let campo_hidden = document.createElement("input");
  	campo_hidden.type = "hidden";
  	campo_hidden.name = this.checkTarget.name;
  	campo_hidden.value = "";
  	this.element.append(campo_hidden);
  }
}
