import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static classes = [ "draggable" ]

	initialize(event) {
		this.dragging = false;
		this.toggleDragClass(event);
	}
	
	toggleDragClass(event) {
		this.element.classList[this.element.scrollWidth <= this.element.offsetWidth && this.element.scrollHeight <= this.element.offsetHeight ? 'remove' : 'add'](...this.draggableClasses);
	}
	
	beginDrag(event) {
		this.dragging = this.leftClick(event) && this.element.classList.contains(...this.draggableClasses);
		this.x = event.pageX;
		this.y = event.pageY;
	}
	
	scrollDrag(event) {
		if(!this.dragging) return true
		let scrollX = this.element.scrollLeft - (event.pageX - this.x), scrollY = this.element.scrollTop - (event.pageY - this.y);
		this.element.scrollTo({top: scrollY, left: scrollX});
		this.x = event.pageX;
		this.y = event.pageY;
	}
	
	stopDrag(event) { this.dragging = false; }
	
	leftClick(event) {
		if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return false
    if ('buttons' in event) return event.buttons === 1
    if ('which' in event) return event.which === 1
    return (event.button == 1 || event.type == 'click')
	}
}
