import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	connect() {
		this.ajustarDimensionesObserver = this.ajustarDimensiones.bind(this);
  	this.resizeObserver = new ResizeObserver(this.ajustarDimensionesObserver);
  	this.resizeObserver.observe(document.documentElement);
	}
	
	disconnect() {
		this.resizeObserver.unobserve(document.documentElement);
		delete this.ajustarDimensionesObserver;
	}
	
	ajustarDimensiones() {
		let width = this.element.naturalWidth;
		this.element.style.width = (Math.min(1, window.innerWidth / (window.innerWidth < 960 ? 1500 : 2000)) * width) + "px";
	}
}
