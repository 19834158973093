import { Controller } from "@hotwired/stimulus"

const cp_url = "/validar-cp";

export default class extends Controller {
	static targets = [ "pais", "entidad", "cp", "municipio", "colonia" ]
	
	connect() { if(this.cpTarget.value != "") this.sugerirDomicilio(); }
	
	limpiarDomicilio() {
		this.cpTarget.value = null;
		this.municipioTarget.value = null;
		this.coloniaTarget.value = null;
		this.entidadTarget.value = null;
	}
	
	sugerirDomicilio() {
		let /*entidad_input = this.entidadTarget,*/ municipio_input = this.municipioTarget, colonia_input = this.coloniaTarget;
		if((this.hasPaisTarget && this.paisTarget.value != "33") || !/^[0-9]{5}$/.test(this.cpTarget.value)) return false;
		fetch(cp_url + "/" + this.cpTarget.value + ".json")
		.then(response => response.json())
		.then((datos_postales) => {
			//entidad_input.value = datos_postales.estado;
			//entidad_input.dispatchEvent(new Event("change"));
			if(typeof datos_postales.municipios === "string") {
				if(municipio_input.nodeName == "SELECT") this.insertarNuevoElemento("input", municipio_input, datos_postales.municipios);
				else municipio_input.value = datos_postales.municipios
			} else {
				if(municipio_input.nodeName == "INPUT") this.insertarNuevoElemento("select", municipio_input, datos_postales.municipios);
				else this.poblarSelect(municipio_input, datos_postales.municipios);
			}
			if(typeof datos_postales.colonias === "string") {
				if(colonia_input.nodeName == "SELECT") this.insertarNuevoElemento("input", colonia_input, datos_postales.colonias);
				else colonia_input.value = datos_postales.colonias
			} else {
				if(colonia_input.nodeName == "INPUT") this.insertarNuevoElemento("select", colonia_input, datos_postales.colonias);
				else this.poblarSelect(colonia_input, datos_postales.colonias);
			}
		})
	}
	
	insertarNuevoElemento(tipo, nodo, opciones) {
		let new_input = document.createElement(tipo), cont = nodo.parentNode, next_node = nodo.nextElementSibling;
		new_input.id = nodo.id;
  	new_input.name = nodo.name;
  	if(tipo == "input") new_input.type = "text";
  	if(nodo.dataset.countryTarget) new_input.dataset.countryTarget = nodo.dataset.countryTarget;
  	if(nodo.dataset.formTarget) new_input.dataset.formTarget = nodo.dataset.formTarget;
  	if(nodo.dataset.action) new_input.dataset.action = nodo.dataset.action;
  	if(nodo.dataset.value) new_input.dataset.value = nodo.dataset.value;
  	new_input.classList = nodo.classList;
  	if(tipo == "select") {
  		this.poblarSelect(new_input, opciones);
  		new_input.value = nodo.dataset.value || new_input.options[0].value;
  		new_input.dispatchEvent(new Event("change"));
  	} else new_input.value = opciones;
  	//if(nodo.closest("field_with_errors") !== null) nodo.closest("field_with_errors").classList[tipo == "select" ? "add" : "remove"]("rightmost");
  	nodo.remove();
  	cont.insertBefore(new_input, next_node);
  	//new_input.value = new_input.dataset.value;
	}
	
	poblarSelect(selector, opciones) {
		selector.innerHTML = "";
		for(let opcion of opciones) {
			let opt = document.createElement("option");
			opt.value = opcion;
			opt.innerText = opcion;
			selector.append(opt);
		}
  }
}
