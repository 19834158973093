import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "campo", "descubre", "ventana", "contenedor", "boton" ]
	static classes = [ "hiddenDescubre" ]
	
	initialize() {
		this.filtros = {tipo: "", titulo: ""}
		this.filas_ant = this.filas;
		this.ajustarAltura(2);
	}
	
	ventanaTargetConnected(element) {
		element.addEventListener("transitionend", () => {
			if(!this.hasBotonTarget) element.style.height = "auto";
		});
	}
	
	descubreTargetConnected(element) {
		element.addEventListener("transitionend", () => {
			if(element.classList.contains(this.hiddenDescubreClass)) element.classList.add("hidden");
		});
	}
  
  ajustarAltura(filas) {
  	this.filas_act = Math.min(filas, this.filas);
  	this.ventanaTarget.style.height = Math.round(this.alto_descubre * this.filas_act) + "px";
  	if(this.filas_act == this.filas && this.hasBotonTarget) { this.botonTarget.remove(); }
  }
  
  abrirVentana() { this.ajustarAltura(this.filas_act + 2); }
  
  comprobarResize() {
  	if(!this.hasBotonTarget) return false
  	else this.ajustarAltura(this.filas_ant != this.filas ? this.filas : this.filas_act);
  }
  
  actualizarFiltro(event) {
  	this.filtros[event.currentTarget.name] = event.currentTarget.value;
  	this.filtrar();
  }
  
  filtrar() {
  	for(let descubre of this.descubreTargets) {
  		let coincidencia = ((this.filtros.tipo == "" || descubre.dataset.tipo == this.filtros.tipo) && (this.filtros.titulo == "" || descubre.querySelector("p").innerText.toLowerCase().indexOf(this.filtros.titulo.toLowerCase()) != -1)), oculto = descubre.classList.contains("hidden");
  		if(coincidencia) setTimeout(() => { descubre.classList.remove("hidden"); }, 300);
  		setTimeout(() => { descubre.classList[coincidencia ? "remove" : "add"](...this.hiddenDescubreClasses); }, (oculto ? 310 : 0));
  	}
  	this.ajustarAltura(this.filas);
  }
  
  get filas() { return Math.round(parseFloat(getComputedStyle(this.contenedorTarget).height) / this.alto_descubre) }
  get alto_descubre() { return parseFloat(getComputedStyle(this.descubreTarget).height) }
}
