import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
const filesize = require("file-size");

class Uploader {
	constructor(file, url, placeholder, multi, name) {
		this.uploader = new DirectUpload(file, url, this);
		this.placeholder = placeholder;
		this.multi = multi;
		this.input_name = name;
	}
	
	upload(file) {
		this.uploader.create((error, blob) => {
  		if(error) { console.log(error); }
  		else {
  			let file_delete = document.createElement("div"), blob_field = document.createElement("input");
  			this.placeholder.querySelector("div").remove();
  			this.placeholder.classList.remove("loading");
				file_delete.classList.add("rounded-full", "before:content-close", "before:font-fa", "before:font-black", "absolute", "top-[-.75rem]", "right-4", "text-gris5", "cursor-pointer");
				file_delete.dataset.action = "click->file-input#eliminarArchivo";
				this.placeholder.append(file_delete);
				blob_field.setAttribute("type", "hidden");
				blob_field.setAttribute("value", blob.signed_id);
				blob_field.name = this.input_name;
				blob_field.multiple = this.multi;
				this.placeholder.append(blob_field);
				this.placeholder.blob_id = blob.id;
  			console.log(blob);
  		}
  		window.uploading_files--;
  		if(window.uploading_files == 0) window.dispatchEvent(new CustomEvent ("file:loading", { bubbles: true, detail: { enabled: true } }));
  	});
	}
	
	directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
  	let progress_bar = this.placeholder.querySelector("div");
  	if(progress_bar == null) {
  		progress_bar = document.createElement("div");
  		progress_bar.classList.add("[--loaded:0%]", "opacity-70", "h-[20px]", "absolute", "w-5/6", "top-[2rem]", "left-1/2", "transform", "-translate-x-1/2", "bg-gris6", "text-white", "font-panel", "text-sm", "after:content-['']", "after:bg-colmex2", "after:h-full", "after:absolute", "after:w-[var(--loaded)]", "after:block", "before:content-[attr(data-loaded)]", "before:absolute", "before:top-1/2", "before:transform", "before:-translate-y-1/2", "before:left-2", "before:z-[1]");
  		this.placeholder.append(progress_bar);
  	}
  	progress_bar.dataset.loaded = (event.loaded * 100 / event.total).toFixed(2) + "%";
  	progress_bar.style.setProperty("--loaded", progress_bar.dataset.loaded);
  }
}

export default class extends Controller {
	static targets = [ "visor", "archivo" ]
	static values = { multi: Boolean, url: String, limit: Number, validation: Object }
	static classes = [ "fileDrag" ]
	
	initialize() {
		this.limit = this.limitValue;
		this.multi = this.multiValue;
		this.url = this.urlValue;
		this.validation = this.validationValue;
		window.uploading_files = 0;
	}
	
	connect() {
		this.deshabilitarSubidas();
		this.revisarHidden();
	}
	
	archivoTargetConnected(element) {
		if(!element.dataset.fileType) return true;
		element.classList.add(this.claseFile(element.dataset.fileType));
		element.blob_id = parseInt(element.dataset.fileId);
		element.removeAttribute("data-file-id");
		element.removeAttribute("data-file-type");
	}
	
	eliminarArchivo(event) {
		let file = event.currentTarget.closest("[data-file-input-target='archivo']");
		file.remove();
		this.deshabilitarSubidas();
		this.revisarHidden();
	}
  
  noEjecutar(event) { event.preventDefault(); return; }
  
  fileClase(event) {
  	event.preventDefault();
  	if(event.currentTarget.classList.contains(...this.fileDragClasses)) return;
  	event.currentTarget.classList.add(...this.fileDragClasses);
  }
  
  normalClase(event) {
  	event.preventDefault();
  	if(!event.currentTarget.classList.contains(...this.fileDragClasses)) return;
  	event.currentTarget.classList.remove(...this.fileDragClasses);
  }
  
  subirArchivo(event) {
  	window.dispatchEvent(new CustomEvent ("file:loading", { bubbles: true, detail: { enabled: false } }));
  	for(let file of event.currentTarget.files) {
			let valid_file = this.validarArchivo(file), file_placeholder = this.fileDefault(file, valid_file);
			this.visorTarget.append(file_placeholder);
			if(valid_file) {
				let uploader = new Uploader(file, this.url, file_placeholder, this.multi, event.currentTarget.name);
				uploader.upload(file);
			} else file_placeholder.append(this.eliminarError);
  		window.uploading_files++;
  		if(this.deshabilitarSubidas()) break;
  	}
  	event.currentTarget.value = null;
  	if(window.uploading_files == 0) window.dispatchEvent(new CustomEvent ("file:loading", { bubbles: true, detail: { enabled: true } }));
  }
  
  validarArchivo(file) {
  	if((this.validation.content_type !== null && !this.validation.content_type.includes(file.type)) || (this.validation.size !== null && this.validation.size < file.size)) {
  		window.uploading_files--;
  		return false;
  	}
  	return true;
  }
  
  deshabilitarSubidas() {
  	let deshabilitado = this.limit == this.archivoTargets.length;
  	this.element.querySelector("input[type='file']").disabled = deshabilitado;
  	this.element.querySelector("label").classList[deshabilitado ? 'add' : 'remove']("hidden");
  	return deshabilitado;
  }
  
  eliminarPlaceholder(event) { event.currentTarget.closest(".panel-archivo-default").remove(); }
  
  cargarArchivo(event) {
  	let lista_archivos = new DataTransfer();
  	event.preventDefault();
  	event.currentTarget.classList.remove(...this.fileDragClasses);
  	[...event.dataTransfer.files].forEach((file, i) => { if(this.multi || (!this.multi && i == event.dataTransfer.items.length - 1)) lista_archivos.items.add(file); });
  	this.element.querySelector("input[type='file']").files = lista_archivos.files;
  	this.element.querySelector("input[type='file']").dispatchEvent(new Event("change"));
  }
  
  fileDefault(file, valid = true) {
  	let file_div = document.createElement("div");
  	file_div.classList.add("panel-archivo-default", (valid ? "loading" : "fail"), "cursor-default", "relative", this.claseFile(file.type));
  	if(valid) file_div.dataset["fileInputTarget"] = "archivo";
  	file_div.innerText = `${file.name} (${filesize(file.size).human('si')})`;
  	return file_div;
  }
  
  claseFile(tipo) {
  	let mime = tipo.split('/');
  	if(mime[0] == "image") return "before:content-image"
  	if(mime[0] == "video") return "before:content-video"
  	if(mime[0] == "audio") return "before:content-audio"
  	if(mime[0] == "application" && mime[1] == "pdf") return "before:content-pdf"
  	if(/zip|rar|7z/.test(mime[1])) return "before:content-zip"
  	return "before:content-file"
  }
  
  revisarHidden() {
  	let mostrar_hidden = this.archivoTargets.length > 0;
  	let campo_hidden = this.element.querySelector("input[type='hidden'][value='']");
		if(!mostrar_hidden && campo_hidden) campo_hidden.remove();
		else if(mostrar_hidden && !campo_hidden) {
			let campo = document.createElement("input");
			campo.type = "hidden";
			campo.value = "";
			campo.name = this.archivoTarget.name;
			this.element.prepend(campo);
		}
  }
  
  get eliminarError() {
  	let file_delete = document.createElement("div");
  	file_delete.classList.add("rounded-full", "before:content-close", "before:font-fa", "before:font-black", "absolute", "top-[-.75rem]", "right-4", "text-gris5", "cursor-pointer");
		file_delete.dataset.action = "click->file-input#eliminarPlaceholder";
		return file_delete;
  }
}
