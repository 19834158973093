import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "close" ]
  static classes = [ "contenido" ]

  abrir(event) {
  	event.preventDefault();
  	let src = new URL(event.currentTarget.href), modal = document.createElement("div"), cont_modal = this.revisarUrl(src), fondo_modal = document.createElement("div");
  	modal.classList.add("fixed", "min-h-screen", "h-max", "w-screen", "bg-black", "bg-opacity-40", "z-40", "flex", "justify-center", "items-center", "inset-0", "scrollbar", "overflow-y-auto");
  	fondo_modal.classList.add("w-full", "h-full", "bg-transparent", "absolute");
  	modal.dataset.controller = "modal";
  	fondo_modal.dataset.modalTarget = "close";
  	modal.dataset.modalBodyClass = "overflow-hidden";
  	modal.append(fondo_modal);
  	modal.append(cont_modal);
  	document.documentElement.classList.add("overflow-hidden");
  	(src.hash != "" ? this.element.parentNode.insertBefore(modal, this.element.nextElementSibling) : document.body.prepend(modal));
  	this.dispatch("abrir", { detail: { element: event.currentTarget, src: src } });
  }
  
  closeTargetConnected(element) { element.dataset.action = "click->modal#cerrar"; }
  
  cerrar(event) {
  	this.element.remove();
  	document.documentElement.classList.remove("overflow-hidden");
  }
  
  revisarUrl(url) {
  	let extension = url.pathname.split("/").reverse()[0].split(".").reverse()[0], contenido;
		if(typeof extension !== "undefined" && this.mime_types.hasOwnProperty(extension)) {
			contenido = document.createElement("object");
			contenido.type = this.mime_types[extension];
			contenido.data = url;
		} else if( url.hash != "" && url.hostname == window.location.host) {
  		contenido = document.querySelector(url.hash).cloneNode(true);
  		contenido.classList.remove("hidden");
		} else {
			if(["www.youtube-nocookie.com", "player.vimeo.com"].includes(url.hostname)) {
				let yt_frame = document.createElement("iframe");
				contenido = document.createElement("div");
				contenido.classList.add("w-11/12", "max-w-[800px]", "mx-auto", "relative", "z-10");
				yt_frame.src = url;
				yt_frame.classList.add("aspect-video", "w-full");
				contenido.append(yt_frame);
			} else {
				contenido = document.createElement("iframe");
				contenido.src = url;
				contenido.loading = "lazy";
			}
		}
		contenido.classList.add(...this.contenidoClasses);
		return contenido
	}
  
	get mime_types() { return {gif: "image/gif", jpeg: "image/jpeg", jpg: "image/jpeg", png: "image/png", svg: "image/svg+xml", avi: "video/x-msvideo", mp3: "audio/mpeg", mpeg: "video/mpeg", oga: "audio/ogg", ogv: "video/ogg", pdf: "application/pdf", wav: "audio/x-wav", weba: "audio/webm", webm: "video/webm", mp4: "video/mp4", m4a: "audio/mp4"} }
}
