import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

	connect() { this.beginScroll = this.beginScroll.bind(this); }

	scrollTo(event) {
		try {
			let hash = new URL(event.currentTarget.href).hash;
			this.elemento = document.querySelector(hash);
			this.top_i = this.elemento.getBoundingClientRect().top - document.querySelector("header").offsetHeight - document.querySelector("#menu-ccc").offsetHeight;
			event.preventDefault();
			this.cancelScroll();
			this.scrolling = requestAnimationFrame(this.beginScroll);
		} catch(e) { return false; }
	}
	
	cancelScroll() {
		if(this.scrolling) cancelAnimationFrame(this.scrolling);
	}
	
	beginScroll() {
		let scrollY = this.elemento.getBoundingClientRect().top;
		scrollTo({top: window.scrollY + Math.sign(this.top_i) * 80});
		if((this.top_i < 0 && this.elemento.getBoundingClientRect().top - document.querySelector("header").offsetHeight - document.querySelector("#menu-ccc").offsetHeight < 0) || (this.top_i >= 0 && this.elemento.getBoundingClientRect().top - document.querySelector("header").offsetHeight - document.querySelector("#menu-ccc").offsetHeight >= 0)) this.scrolling = requestAnimationFrame(this.beginScroll);
		else scroll({top: this.elemento.getBoundingClientRect().top - document.querySelector("header").offsetHeight + window.scrollY - document.querySelector("#menu-ccc").offsetHeight});
	}
}
