import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "seccion", "tab" ]
	static classes = [ "hiddenSect", "activeTab" ]
	static values = { indice: { type: Number, default: -1 }, local: { type: Boolean, default: true } }
	
	connect() {
		this.local = this.localValue;
		if(this.indiceValue == -1) return true;
		this.tabTargets[this.indiceValue].classList.add(...this.activeTabClasses);
		if(this.local) this.seccionTargets[this.indiceValue].classList.remove(...this.hiddenSectClasses);
	}
	
	toggle(event) {
		let ind_activa = this.tabTargets.findIndex(tab => tab.classList.contains(this.activeTabClass)), ind_nuevo = this.tabTargets.indexOf(event.currentTarget);
		if(ind_activa == ind_nuevo) {
			event.preventDefault();
			return false
		}
		if(ind_activa != -1) {
			this.tabTargets[ind_activa].classList.remove(...this.activeTabClasses);
			if(this.local) this.seccionTargets[ind_activa].classList.add(...this.hiddenSectClasses);
		}
		event.currentTarget.classList.add(...this.activeTabClasses);
		if(this.local) this.seccionTargets[ind_nuevo].classList.remove(...this.hiddenSectClasses);
	}
}
