import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["novedad"]

	connect() {
		this.novedades = this.novedadTargets;
		this.desplegarAleatorios();
		this.timer = setInterval(() => {
			this.desplegarAleatorios();
		}, 5000);
	}
	
	disconnect() {
		clearInterval(this.timer);
	}
	
	desplegarAleatorios() {
		this.permutar();
		this.element.innerHTML = "";
		for(let novedad of this.novedades.slice(0, this.max_novedades)) {
			this.element.append(novedad);
		}
	}
	
	permutar() {
		for (let i = this.novedades.length - 1; i > 0; i--) {
		  let j = Math.floor(Math.random() * (i + 1));
		  [this.novedades[i], this.novedades[j]] = [this.novedades[j], this.novedades[i]];
		}
	}
	
	get max_novedades() {
		let ancho = window.innerWidth, cantidad;
		if(ancho < 640) cantidad = 2
		else if(ancho < 960) cantidad = 3
		else if(ancho < 1200) cantidad = 4
		else  cantidad = 5
	}
}
