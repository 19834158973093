import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
	connect() { this.configTribute(); }
	
	disconnect() { this.tribute.detach(this.element); }
	
	configTribute() {
		this.tribute = new Tribute({allowSpaces: true, lookup: 'nombre', values: this.desglosar})
		this.tribute.attach(this.element);
		this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
		this.element.addEventListener("tribute-replaced", this.replaced);
	}
	
	desglosar(text, callback) {
		fetch(`/menciones/instituciones.json?query=${text}`)
		.then(response => response.json())
		.then(objects => callback(objects))
		.catch(error => callback([]));
	}
	
	replaced(event) {
		let mention = event.detail.item.original;
		let attachment = new Trix.Attachment({ sgid: mention.sgid, content: mention.content });
		this.editor.insertAttachment(attachment);
	}
	
	_pasteHtml(html, start, end) {
		let position = this.editor.getPosition();
		this.editor.setSelectedRange([position - end, position]);
		this.editor.deleteInDirection("backward");
	}
	
	get editor() { return this.element.editor }
}
